

import { DxForm } from 'devextreme-vue/form';

export default {
  components: { DxForm },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      items: ['Address', 'City', 'Region', 'PostalCode', 'Country', 'Phone'],
    };
  },
  methods: {
    customizeItem(item) {
      item.template = 'form-item';
    },
  },
};
